import React from 'react';
import { IoArrowBack } from 'react-icons/io5';
import { Link } from 'react-router-dom';

interface InvoiceHeaderProps {
  invoiceNumber: string;
  loading: boolean;
  onSave: () => void;
  onPrevious: () => void;
  onNext: () => void;
  hasPrevious: boolean;
  hasNext: boolean;
}

const InvoiceHeader: React.FC<InvoiceHeaderProps> = ({ 
  invoiceNumber, 
  loading, 
  onSave,
  onPrevious,
  onNext,
  hasPrevious,
  hasNext 
}) => {
  return (
    <div className="flex justify-between items-center pt-6">
      <div className="flex items-center gap-4">
        <Link to="/dashboard" className="text-gray-600 hover:text-gray-800">
          <IoArrowBack size={24} />
        </Link>
        <h1 className="text-2xl font-semibold">
          {loading ? 'Loading...' : `Sąskaita ${invoiceNumber || ''}`}
        </h1>
      </div>
      <div className="flex items-center gap-3">
        <div className="flex gap-2">
          <button
            onClick={onPrevious}
            disabled={!hasPrevious}
            className={`px-3 py-1.5 text-sm border border-gray-300 rounded-lg ${
              hasPrevious ? 'hover:bg-gray-50 text-gray-700' : 'text-gray-400'
            }`}
          >
            Atgal
          </button>
          <button
            onClick={onNext}
            disabled={!hasNext}
            className={`px-3 py-1.5 text-sm border border-gray-300 rounded-lg ${
              hasNext ? 'hover:bg-gray-50 text-gray-700' : 'text-gray-400'
            }`}
          >
            Pirmyn
          </button>
        </div>
        <div className="h-6 w-px bg-gray-200 mx-2"></div>
        <button className="px-4 py-2 text-sm bg-white text-gray-700 border border-gray-300 rounded-lg hover:bg-gray-50 flex items-center gap-2">
          <span>Eksportuoti</span>
        </button>
        <button 
          onClick={onSave}
          className="px-4 py-2 text-sm bg-blue-600 text-white rounded-lg hover:bg-blue-700 flex items-center gap-2"
        >
          <span>Išsaugoti pakeitimus</span>
        </button>
      </div>
    </div>
  );
};

export default InvoiceHeader;
