import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { getUserAccessibleClients } from '../services/clientService';
import { getDocumentStats, getPaginatedDocuments } from '../services/documentService';
import { ClientData, DocumentStats, DocumentData } from '../types/dashboard';

// Import components
import Sidebar from './dashboard/Sidebar';
import StatsOverview from './dashboard/StatsOverview';
import TableFilters from './dashboard/TableFilters';
import DocumentsTable from './dashboard/DocumentsTable';
import Pagination from './dashboard/Pagination';
import DashboardHeader from './dashboard/DashboardHeader';

const Dashboard: React.FC = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [clients, setClients] = useState<ClientData[]>([]);
  const [selectedClient, setSelectedClient] = useState<ClientData | null>(null);
  const [stats, setStats] = useState<DocumentStats | null>(null);
  const [documents, setDocuments] = useState<DocumentData[]>([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [statusFilter, setStatusFilter] = useState('all');
  const [currentPage, setCurrentPage] = useState(1);
  const [searchCompany, setSearchCompany] = useState('');
  const [totalPages, setTotalPages] = useState(1);
  const PAGE_SIZE = 10;

  useEffect(() => {
    const loadClients = async () => {
      if (!user?.user_id) return;
      
      try {
        const clientsData = await getUserAccessibleClients(user.user_id);
        const uniqueClients = Array.from(
          new Map(clientsData.map(item => [item.client_id, item])).values()
        );
        setClients(uniqueClients);
        if (uniqueClients.length > 0) {
          setSelectedClient(uniqueClients[0]);
        }
      } catch (error) {
        console.error('Error loading clients:', error);
      }
    };
    loadClients();
  }, [user]);

  useEffect(() => {
    const loadStats = async () => {
      if (selectedClient) {
        try {
          setLoading(true);
          const statsData = await getDocumentStats(selectedClient.client_id);
          setStats(statsData);
        } catch (error) {
          console.error('Error loading stats:', error);
        } finally {
          setLoading(false);
        }
      }
    };
    loadStats();
  }, [selectedClient]);

  useEffect(() => {
    const loadDocuments = async () => {
      if (selectedClient && user?.user_id) {
        try {
          setLoading(true);
          const filters = {
            status: statusFilter === 'all' ? null : statusFilter,
            search: searchQuery || null
          };
          
          const documentsData = await getPaginatedDocuments(
            user.user_id,
            selectedClient.client_id,
            currentPage,
            PAGE_SIZE,
            filters
          );

          if (documentsData) {
            setDocuments(documentsData.documents || []);
            setTotalPages(Math.ceil((documentsData.total_count || 0) / PAGE_SIZE));
          }
        } catch (error) {
          console.error('Error loading documents:', error);
        } finally {
          setLoading(false);
        }
      }
    };
    loadDocuments();
  }, [selectedClient, currentPage, statusFilter, searchQuery, user?.user_id]);

  const handleInvoiceClick = (document: DocumentData) => {
    if (!selectedClient) return;
    navigate(`/invoice/${document.document_id}/${selectedClient.client_id}`);
  };

  if (loading && !stats) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-black"></div>
      </div>
    );
  }

  return (
    <div className="flex h-screen bg-gray-50 overflow-hidden">
      <Sidebar
        clients={clients}
        selectedClient={selectedClient}
        setSelectedClient={setSelectedClient}
        searchCompany={searchCompany}
        setSearchCompany={setSearchCompany}
      />

      <div className="flex-1 flex flex-col overflow-hidden">
        <div className="p-8 flex-shrink-0">
          <div className="max-w-7xl mx-auto">
            <DashboardHeader companyName={selectedClient?.company_name} />
            <StatsOverview stats={stats} />
          </div>
        </div>

        <div className="flex-1 overflow-y-auto px-8 pb-8">
          <div className="max-w-7xl mx-auto">
            <div className="bg-white rounded-lg shadow">
              <div className="px-6 py-4 border-b border-gray-200">
                <div className="flex justify-between items-center">
                  <h2 className="text-lg font-medium text-gray-900">Įmonės Sąskaitos</h2>
                  <TableFilters
                    statusFilter={statusFilter}
                    setStatusFilter={setStatusFilter}
                    searchQuery={searchQuery}
                    setSearchQuery={setSearchQuery}
                    setCurrentPage={setCurrentPage}
                  />
                </div>
              </div>

              <DocumentsTable
                documents={documents}
                handleInvoiceClick={handleInvoiceClick}
              />

              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                setCurrentPage={setCurrentPage}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
