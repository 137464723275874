import React, { useEffect, useState, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { supabase } from '../config/supabase';
import InvoiceHeader from './invoice/InvoiceHeader';
import InvoicePreview from './invoice/InvoicePreview';
import InvoiceForm from './invoice/InvoiceForm';
import { Invoice } from '../types/invoice';

const InvoiceDetails: React.FC = () => {
  const navigate = useNavigate();
  const params = useParams<{ 
    invoiceId?: string;
    documentId?: string;
    clientId?: string;
  }>();
  
  const documentId = params.invoiceId || params.documentId;
  const urlClientId = params.clientId;

  const [loading, setLoading] = useState(true);
  const [invoice, setInvoice] = useState<Invoice | null>(null);
  const [fileUrl, setFileUrl] = useState<string | null>(null);
  const [clientId, setClientId] = useState<string | null>(urlClientId || null);
  const [navigation, setNavigation] = useState<{
    previous_id: string | null;
    next_id: string | null;
    has_previous: boolean;
    has_next: boolean;
  }>({
    previous_id: null,
    next_id: null,
    has_previous: false,
    has_next: false,
  });

  const fetchInvoiceDetails = useCallback(async () => {
    try {
      const user = await supabase.auth.getUser();
      if (!user.data?.user || !clientId || !documentId) {
        return;
      }

      const { data, error } = await supabase.rpc('get_single_document_details', {
        p_user_id: user.data.user.id,
        p_document_id: documentId,
        p_client_id: clientId,
        p_filters: {},
        p_sort_direction: 'DESC',
        p_sort_field: 'upload_date'
      });

      if (error) {
        console.error('Error fetching document details:', error);
        return;
      }

      if (data?.document) {
        const documentData = data.document;

        console.log('Document confidence:', documentData.confidence);

        if (documentData.file_path) {
          const { data: fileData, error: fileError } = await supabase.storage
            .from('documents')
            .createSignedUrl(documentData.file_path, 3600);

          if (fileError) {
            console.error('Error getting file URL:', fileError);
          } else if (fileData) {
            setFileUrl(fileData.signedUrl);
          }
        }

        setInvoice({
          invoice_number: documentData.invoice_id || '',
          date: documentData.invoice_date || '',
          date_due: documentData.date_due || '',
          supplier_name: documentData.supplier_name || '',
          supplier_address: documentData.supplier_address || '',
          supplier_tax_id: documentData.supplier_tax_id || '',
          supplier_registration: documentData.supplier_registration || '',
          receiver_name: documentData.receiver_name || '',
          receiver_address: documentData.receiver_address || '',
          receiver_tax_id: documentData.receiver_tax_id || '',
          receiver_registration: documentData.receiver_registration || '',
          items: documentData.line_items?.map((item: any) => ({
            description: item.description || '',
            product_code: item.product_code || '',
            quantity: item.quantity || '',
            unit: item.unit || '',
            unit_price: typeof item.unit_price === 'number' ? item.unit_price : null,
            amount: typeof item.amount === 'number' ? item.amount : null,
            confidence: item.confidence || {}
          })) || [],
          payment_terms: documentData.payment_terms || '',
          supplier_iban: documentData.supplier_iban || '',
          net_amount: documentData.net_amount ? parseFloat(documentData.net_amount) : null,
          tax_percentage: documentData.tax_percentage ? parseFloat(documentData.tax_percentage) : null,
          total_tax_amount: documentData.total_tax_amount ? parseFloat(documentData.total_tax_amount) : null,
          total_amount: documentData.total_amount ? parseFloat(documentData.total_amount) : null,
          mime_type: documentData.mime_type || '',
          currency: documentData.currency || '',
          confidence: {
            invoice_id: documentData.confidence?.invoice_id,
            invoice_date: documentData.confidence?.invoice_date,
            supplier_name: documentData.confidence?.supplier_name,
            supplier_address: documentData.confidence?.supplier_address,
            supplier_tax_id: documentData.confidence?.supplier_tax_id,
            supplier_registration: documentData.confidence?.supplier_registration,
            receiver_name: documentData.confidence?.receiver_name,
            receiver_address: documentData.confidence?.receiver_address,
            receiver_tax_id: documentData.confidence?.receiver_tax_id,
            receiver_registration: documentData.confidence?.receiver_registration,
            currency: documentData.confidence?.currency,
            net_amount: documentData.confidence?.net_amount,
            tax_percentage: documentData.confidence?.tax_percentage,
            total_tax_amount: documentData.confidence?.total_tax_amount,
            total_amount: documentData.confidence?.total_amount,
            date_due: documentData.confidence?.date_due,
            payment_terms: documentData.confidence?.payment_terms,
            supplier_iban: documentData.confidence?.supplier_iban
          }
        });

        setNavigation(data.navigation);
      }
    } catch (error) {
      console.error('Error fetching invoice details:', error);
    } finally {
      setLoading(false);
    }
  }, [clientId, documentId]);

  useEffect(() => {
    const fetchClientId = async () => {
      if (!urlClientId && documentId) {
        try {
          const { data: docData, error: docError } = await supabase
            .from('documents')
            .select('uploaded_file_id')
            .eq('document_id', documentId)
            .limit(1)
            .maybeSingle();

          if (docError) {
            console.error('Error fetching document:', docError);
            return;
          }

          if (docData?.uploaded_file_id) {
            const { data: uploadData, error: uploadError } = await supabase
              .from('uploaded_documents')
              .select('client_id')
              .eq('document_id', docData.uploaded_file_id)
              .limit(1)
              .maybeSingle();

            if (uploadError) {
              console.error('Error fetching upload record:', uploadError);
              return;
            }

            if (uploadData?.client_id) {
              setClientId(uploadData.client_id);
            }
          } else {
            const { data: directData, error: directError } = await supabase
              .from('uploaded_documents')
              .select('client_id')
              .eq('document_id', documentId)
              .limit(1)
              .maybeSingle();

            if (directError) {
              console.error('Error in direct lookup:', directError);
              return;
            }

            if (directData?.client_id) {
              setClientId(directData.client_id);
            }
          }
        } catch (error) {
          console.error('Error in fetchClientId:', error);
        }
      }
    };

    fetchClientId();
  }, [urlClientId, documentId]);

  useEffect(() => {
    if (clientId && documentId) {
      fetchInvoiceDetails();
    }
  }, [clientId, documentId, fetchInvoiceDetails]);

  const handleInputChange = (e: { target: { name: string; value: string } }) => {
    if (!invoice) return;
    
    setInvoice(prev => {
      if (!prev) return prev;

      const { name, value } = e.target;
      
      if (name === 'items') {
        try {
          const parsedItems = JSON.parse(value);
          return { ...prev, items: parsedItems };
        } catch (error) {
          console.error('Error parsing items:', error);
          return prev;
        }
      }
      
      if (name.includes('.')) {
        const [parentKey, index, childKey] = name.split('.');
        if (parentKey === 'items' && !isNaN(Number(index))) {
          const items = [...(prev.items || [])];
          if (!items[Number(index)]) {
            items[Number(index)] = {};
          }
          items[Number(index)] = {
            ...items[Number(index)],
            [childKey]: value
          };
          return { ...prev, items };
        }
      }

      return { ...prev, [name]: value };
    });
  };

  const handleNavigation = (direction: 'prev' | 'next') => {
    const newDocId = direction === 'prev' ? navigation.previous_id : navigation.next_id;
    if (newDocId && clientId) {
      navigate(`/clients/${clientId}/invoices/${newDocId}`);
    }
  };

  const handleSaveChanges = async () => {
    if (!invoice || !documentId || !clientId) return;

    try {
      const user = await supabase.auth.getUser();
      if (!user.data?.user) {
        console.error('No user found');
        return;
      }

      // Validate and format dates
      const formattedInvoiceDate = invoice.date ? new Date(invoice.date).toISOString().split('T')[0] : null;
      const formattedDueDate = invoice.date_due ? new Date(invoice.date_due).toISOString().split('T')[0] : null;

      if (!formattedInvoiceDate) {
        console.error('Invalid invoice date');
        return;
      }

      // First get the uploaded_file_id from documents
      const { data: docData, error: docError } = await supabase
        .from('documents')
        .select('uploaded_file_id')
        .eq('document_id', documentId)
        .single();

      if (docError) {
        console.error('Error getting document:', docError);
        return;
      }

      if (!docData?.uploaded_file_id) {
        console.error('No uploaded file ID found');
        return;
      }

      // Update the documents table
      const { error: updateError } = await supabase
        .from('documents')
        .update({
          invoice_id: invoice.invoice_number,
          invoice_date: formattedInvoiceDate,
          date_due: formattedDueDate,
          supplier_name: invoice.supplier_name,
          supplier_address: invoice.supplier_address,
          supplier_tax_id: invoice.supplier_tax_id,
          supplier_registration: invoice.supplier_registration,
          receiver_name: invoice.receiver_name,
          receiver_address: invoice.receiver_address,
          receiver_tax_id: invoice.receiver_tax_id,
          receiver_registration: invoice.receiver_registration,
          payment_terms: invoice.payment_terms,
          supplier_iban: invoice.supplier_iban,
          net_amount: invoice.net_amount,
          tax_percentage: invoice.tax_percentage,
          total_tax_amount: invoice.total_tax_amount,
          total_amount: invoice.total_amount,
          currency: invoice.currency,
          items: invoice.items?.map(item => ({
            description: item.description || '',
            product_code: item.product_code || '',
            quantity: item.quantity || '',
            unit: item.unit || '',
            unit_price: item.unit_price || null,
            amount: item.amount || null
          })) || []
        })
        .eq('document_id', documentId);

      if (updateError) {
        console.error('Error updating document:', updateError);
        return;
      }

      // Refresh the invoice details to get the latest data
      await fetchInvoiceDetails();
    } catch (error) {
      console.error('Error in handleSaveChanges:', error);
    }
  };

  if (loading) {
    return (
      <div className="px-4">
        <div className="animate-pulse">
          <div className="h-8 bg-gray-200 rounded w-1/4 mb-6"></div>
          <div className="grid grid-cols-2 gap-6">
            <div className="h-[800px] bg-gray-200 rounded"></div>
            <div className="space-y-4">
              <div className="h-4 bg-gray-200 rounded w-3/4"></div>
              <div className="h-4 bg-gray-200 rounded w-1/2"></div>
              <div className="h-4 bg-gray-200 rounded w-2/3"></div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (!invoice) {
    return null;
  }

  return (
    <div className="container mx-auto px-4">
      <div className="max-w-[1920px] mx-auto">
        <InvoiceHeader 
          invoiceNumber={invoice?.invoice_number || ''} 
          loading={loading}
          onSave={handleSaveChanges}
          onPrevious={() => handleNavigation('prev')}
          onNext={() => handleNavigation('next')}
          hasPrevious={navigation.has_previous}
          hasNext={navigation.has_next}
        />
        <div className="grid grid-cols-2 gap-6 mt-6">
          <div className="max-h-[800px] overflow-y-auto pr-4">
            <InvoiceForm 
              invoice={invoice} 
              handleInputChange={handleInputChange}
            />
          </div>
          <div>
            <InvoicePreview 
              fileUrl={fileUrl} 
              mimeType={invoice.mime_type || ''}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvoiceDetails;
