import React from 'react';
import { Tooltip } from 'react-tooltip';
import { CheckCircleIcon, InformationCircleIcon } from '@heroicons/react/24/solid';

interface ConfidenceIndicatorProps {
  confidence: number | undefined;
  fieldName: string;
}

const ConfidenceIndicator: React.FC<ConfidenceIndicatorProps> = ({ confidence, fieldName }) => {
  // If confidence is undefined or null, don't render anything
  if (confidence === undefined || confidence === null) {
    return null;
  }

  const isHighConfidence = confidence >= 0.985;
  const tooltipId = `confidence-tooltip-${fieldName}`;

  return (
    <div className="inline-flex items-center ml-2">
      <div
        data-tooltip-id={tooltipId}
        data-tooltip-content={`Confidence: ${(confidence * 100).toFixed(2)}%`}
        className="cursor-help"
      >
        {isHighConfidence ? (
          <CheckCircleIcon className="h-5 w-5 text-green-500" />
        ) : (
          <InformationCircleIcon className="h-5 w-5 text-orange-500" />
        )}
      </div>
      <Tooltip id={tooltipId} />
    </div>
  );
};

export default ConfidenceIndicator;
