import { supabase } from '../config/supabase';
import { DocumentStats, DocumentFilters, PaginatedDocuments } from '../types/dashboard';

export const getDocumentStats = async (clientId: string): Promise<DocumentStats> => {
  const { data, error } = await supabase
    .rpc('get_company_documents', {
      p_client_id: clientId
    });

  if (error) {
    throw new Error(error.message);
  }

  return data as DocumentStats;
};

export const getPaginatedDocuments = async (
  userId: string,
  clientId: string,
  page: number,
  pageSize: number,
  filters: DocumentFilters,
  sortField: string = 'upload_date',
  sortDirection: 'ASC' | 'DESC' = 'DESC'
): Promise<PaginatedDocuments> => {
  const { data, error } = await supabase
    .rpc('get_paginated_documents', {
      p_user_id: userId,
      p_client_id: clientId,
      p_page: page,
      p_page_size: pageSize,
      p_filters: filters,
      p_sort_field: sortField,
      p_sort_direction: sortDirection
    });

  if (error) {
    throw new Error(error.message);
  }

  return data as PaginatedDocuments;
};

export const getDocumentDetails = async (
  userId: string,
  documentId: string,
  clientId: string,
  filters: DocumentFilters = {},
  sortDirection: string = 'DESC',
  sortField: string = 'upload_date'
) => {
  // Ensure parameters are passed in the correct order as defined in the schema
  const { data, error } = await supabase
    .rpc('get_single_document_details', {
      p_user_id: userId,
      p_document_id: documentId,
      p_client_id: clientId,
      p_filters: filters || {},
      p_sort_direction: sortDirection,
      p_sort_field: sortField
    });

  if (error) {
    console.error('Error in getDocumentDetails:', error);
    throw new Error(`Failed to get document details: ${error.message}`);
  }

  if (!data) {
    console.error('No data returned from get_single_document_details');
    throw new Error('No document details found');
  }

  return data;
};

export const uploadDocuments = async (
  clientId: string,
  userId: string,
  files: File[]
): Promise<string[]> => {
  const filesData = files.map(file => ({
    name: file.name,
    size: file.size,
    type: file.type
  }));

  const { data, error } = await supabase
    .rpc('create_uploaded_documents', {
      p_user_id: userId,
      p_client_id: clientId,
      p_file_paths: filesData.map(f => f.name),
      p_file_sizes: filesData.map(f => f.size),
      p_mime_types: filesData.map(f => f.type)
    });

  if (error) {
    throw new Error(error.message);
  }

  return data as string[];
};
