import React from 'react';
import { FormTextField, FormTextArea } from '../ui/FormField';
import { InvoiceFormProps } from '../../types/invoice';

interface FormSectionProps {
  title: string;
  children: React.ReactNode;
}

const FormSection: React.FC<FormSectionProps> = ({ title, children }) => (
  <div className="mb-8 bg-white p-6 rounded-lg shadow-sm border border-gray-100">
    <h3 className="text-lg font-semibold text-gray-900 mb-5 pb-3 border-b border-gray-100">{title}</h3>
    {children}
  </div>
);

interface ConfidenceInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  confidence?: number;
}

const ConfidenceInput: React.FC<ConfidenceInputProps> = ({ confidence, ...props }) => {
  return <input {...props} />;
};

const InvoiceForm: React.FC<InvoiceFormProps> = ({ invoice, handleInputChange }) => {
  const formatCurrency = (value?: number | null): string => {
    if (!value) return '';
    return new Intl.NumberFormat('lt-LT', {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits: 2
    }).format(value);
  };

  const createChangeHandler = (name: string) => (value: string) => {
    handleInputChange({
      target: {
        name,
        value
      }
    });
  };

  return (
    <div className="bg-gray-50 p-6 rounded-lg space-y-6">
      <FormSection title="Sąskaitos faktūros informacija">
        <div className="grid grid-cols-3 gap-6">
          <FormTextField
            label="Sąskaitos numeris"
            name="invoice_number"
            value={invoice?.invoice_number || ''}
            onChange={createChangeHandler('invoice_number')}
            placeholder="SF-001"
            confidence={invoice?.confidence?.invoice_id}
          />
          <FormTextField
            label="Data"
            type="date"
            name="date"
            value={invoice?.date || ''}
            onChange={createChangeHandler('date')}
            confidence={invoice?.confidence?.invoice_date}
          />
          <FormTextField
            label="Mokėjimo terminas"
            type="date"
            name="date_due"
            value={invoice?.date_due || ''}
            onChange={createChangeHandler('date_due')}
            confidence={invoice?.confidence?.date_due}
          />
        </div>
      </FormSection>

      <FormSection title="Tiekėjo informacija">
        <div className="grid grid-cols-2 gap-6">
          <FormTextField
            label="Įmonės pavadinimas"
            name="supplier_name"
            value={invoice?.supplier_name || ''}
            onChange={createChangeHandler('supplier_name')}
            placeholder="UAB Įmonė"
            confidence={invoice?.confidence?.supplier_name}
          />
          <FormTextArea
            label="Adresas"
            name="supplier_address"
            value={invoice?.supplier_address || ''}
            onChange={createChangeHandler('supplier_address')}
            placeholder="Gatvė g. 1, Miestas"
            confidence={invoice?.confidence?.supplier_address}
          />
          <FormTextField
            label="PVM mokėtojo kodas"
            name="supplier_tax_id"
            value={invoice?.supplier_tax_id || ''}
            onChange={createChangeHandler('supplier_tax_id')}
            placeholder="LT100000000000"
            confidence={invoice?.confidence?.supplier_tax_id}
          />
          <FormTextField
            label="Įmonės kodas"
            name="supplier_registration"
            value={invoice?.supplier_registration || ''}
            onChange={createChangeHandler('supplier_registration')}
            placeholder="300000000"
            confidence={invoice?.confidence?.supplier_registration}
          />
        </div>
      </FormSection>

      <FormSection title="Gavėjo informacija">
        <div className="grid grid-cols-2 gap-6">
          <FormTextField
            label="Įmonės pavadinimas"
            name="receiver_name"
            value={invoice?.receiver_name || ''}
            onChange={createChangeHandler('receiver_name')}
            placeholder="UAB Klientas"
            confidence={invoice?.confidence?.receiver_name}
          />
          <FormTextArea
            label="Adresas"
            name="receiver_address"
            value={invoice?.receiver_address || ''}
            onChange={createChangeHandler('receiver_address')}
            placeholder="Gatvė g. 1, Miestas"
            confidence={invoice?.confidence?.receiver_address}
          />
          <FormTextField
            label="PVM mokėtojo kodas"
            name="receiver_tax_id"
            value={invoice?.receiver_tax_id || ''}
            onChange={createChangeHandler('receiver_tax_id')}
            placeholder="LT100000000000"
            confidence={invoice?.confidence?.receiver_tax_id}
          />
          <FormTextField
            label="Įmonės kodas"
            name="receiver_registration"
            value={invoice?.receiver_registration || ''}
            onChange={createChangeHandler('receiver_registration')}
            placeholder="300000000"
            confidence={invoice?.confidence?.receiver_registration}
          />
        </div>
      </FormSection>

      <div className="mb-8">
        <h2 className="text-xl font-medium text-gray-700 mb-4">Prekės / Paslaugos</h2>
        <div className="bg-white rounded-lg shadow-sm border border-gray-200 overflow-hidden">
          {/* Header */}
          <div className="grid grid-cols-10 gap-4 p-4 bg-gray-50 border-b border-gray-200">
            <div className="col-span-4 text-sm font-medium text-gray-600">Aprašymas</div>
            <div className="col-span-2 text-sm font-medium text-gray-600 text-center">Kiekis</div>
            <div className="col-span-2 text-sm font-medium text-gray-600 text-right pr-4">Kaina</div>
            <div className="col-span-2 text-sm font-medium text-gray-600 text-right">Suma</div>
          </div>
          
          {/* Line Items */}
          <div className="divide-y divide-gray-100">
            {Array.isArray(invoice?.items) ? invoice.items.map((item, index) => (
              <div key={index} className="grid grid-cols-10 gap-4 p-4 hover:bg-gray-50 items-center">
                <div className="col-span-4">
                  <ConfidenceInput
                    type="text"
                    className="w-full border-gray-200 rounded-md text-gray-900"
                    name={`items.${index}.description`}
                    value={item.description || ''}
                    onChange={(e) => createChangeHandler(`items.${index}.description`)(e.target.value)}
                    placeholder="Prekės ar paslaugos aprašymas"
                    confidence={invoice?.confidence?.items?.[index]?.description}
                  />
                </div>
                <div className="col-span-2">
                  <ConfidenceInput
                    type="text"
                    className="w-full border-gray-200 rounded-md text-gray-900 text-center"
                    name={`items.${index}.quantity`}
                    value={item.quantity || ''}
                    onChange={(e) => createChangeHandler(`items.${index}.quantity`)(e.target.value)}
                    confidence={invoice?.confidence?.items?.[index]?.quantity}
                  />
                </div>
                <div className="col-span-2">
                  <ConfidenceInput
                    type="text"
                    className="w-full border-gray-200 rounded-md text-gray-900 text-right pr-4"
                    name={`items.${index}.unit_price`}
                    value={item.unit_price?.toString() || ''}
                    onChange={(e) => createChangeHandler(`items.${index}.unit_price`)(e.target.value)}
                    confidence={invoice?.confidence?.items?.[index]?.unit_price}
                  />
                </div>
                <div className="col-span-2">
                  <ConfidenceInput
                    type="text"
                    className="w-full border-gray-200 rounded-md text-gray-900 text-right bg-gray-50"
                    name={`items.${index}.amount`}
                    value={item.amount?.toString() || ''}
                    onChange={(e) => createChangeHandler(`items.${index}.amount`)(e.target.value)}
                    disabled
                    confidence={invoice?.confidence?.items?.[index]?.amount}
                  />
                </div>
              </div>
            )) : null}
          </div>

          {/* Add Line Item Button */}
          <div className="p-4 border-t border-gray-200">
            <button
              type="button"
              className="flex items-center text-gray-600 hover:text-gray-800 font-medium"
              onClick={() => {
                const newItem = {
                  description: '',
                  quantity: '',
                  unit: '',
                  unit_price: null,
                  amount: null,
                  confidence: {}
                };
                if (invoice) {
                  const currentItems = Array.isArray(invoice.items) ? invoice.items : [];
                  handleInputChange({
                    target: {
                      name: 'items',
                      value: JSON.stringify([...currentItems, newItem])
                    }
                  });
                }
              }}
            >
              <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
              </svg>
              Pridėti eilutę
            </button>
          </div>
        </div>
      </div>

      <FormSection title="Mokėjimo informacija">
        <div className="grid grid-cols-2 gap-6">
          <FormTextField
            label="Mokėjimo sąlygos"
            name="payment_terms"
            value={invoice?.payment_terms || ''}
            onChange={createChangeHandler('payment_terms')}
            placeholder="Apmokėti per 30 dienų"
            confidence={invoice?.confidence?.payment_terms}
          />
          <FormTextField
            label="Sąskaitos numeris"
            name="supplier_iban"
            value={invoice?.supplier_iban || ''}
            onChange={createChangeHandler('supplier_iban')}
            placeholder="LT00 0000 0000 0000 0000"
            confidence={invoice?.confidence?.supplier_iban}
          />
        </div>
      </FormSection>

      <FormSection title="Sumos">
        <div className="grid grid-cols-2 gap-6">
          <FormTextField
            label="Suma be PVM"
            name="net_amount"
            value={formatCurrency(invoice?.net_amount)}
            onChange={createChangeHandler('net_amount')}
            disabled
            confidence={invoice?.confidence?.net_amount}
          />
          <FormTextField
            label="PVM suma"
            name="total_tax_amount"
            value={formatCurrency(invoice?.total_tax_amount)}
            onChange={createChangeHandler('total_tax_amount')}
            disabled
            confidence={invoice?.confidence?.total_tax_amount}
          />
          <div className="flex items-center gap-4">
            <FormTextField
              label="PVM procentas"
              type="number"
              name="tax_percentage"
              value={invoice?.tax_percentage?.toString() || ''}
              onChange={createChangeHandler('tax_percentage')}
              className="text-right"
              confidence={invoice?.confidence?.tax_percentage}
            />
            <span className="text-gray-500 mt-6">%</span>
          </div>
          <FormTextField
            label="Bendra suma"
            name="total_amount"
            value={formatCurrency(invoice?.total_amount)}
            onChange={createChangeHandler('total_amount')}
            disabled
            className="font-semibold text-lg"
            confidence={invoice?.confidence?.total_amount}
          />
        </div>
      </FormSection>
    </div>
  );
};

export default InvoiceForm;
