import React from 'react';
import { TextAreaProps } from '../../types';

const TextArea: React.FC<TextAreaProps> = ({
  value,
  onChange,
  placeholder = '',
  className = '',
  rows = 3,
  name,
  confidence,
  ...props
}) => {
  return (
    <textarea
      value={value}
      onChange={(e) => onChange(e.target.value)}
      placeholder={placeholder}
      className={`w-full rounded-lg border border-gray-300 px-4 py-2 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 ${className}`}
      rows={rows}
      name={name}
      {...props}
    />
  );
};

export default TextArea;
