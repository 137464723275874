import React from 'react';
import { DashboardHeaderProps } from '../../types/dashboard';

const DashboardHeader: React.FC<DashboardHeaderProps> = ({ companyName }) => {
  return (
    <div className="mb-8">
      <h1 className="text-2xl font-bold text-gray-900">
        {companyName ? `${companyName} dokumentai` : 'Dokumentai'}
      </h1>
      <p className="mt-2 text-sm text-gray-700">
        Peržiūrėkite ir valdykite įmonės dokumentus
      </p>
    </div>
  );
};

export default DashboardHeader;
