import React from 'react';
import TextField from './TextField';
import TextArea from './TextArea';
import { TextFieldProps, TextAreaProps } from '../../types';
import ConfidenceIndicator from './ConfidenceIndicator';

interface FormFieldProps extends TextFieldProps {
  label: string;
  type?: string;
  disabled?: boolean;
  confidence?: number;
  name: string;
}

interface FormTextAreaProps extends TextAreaProps {
  label: string;
  confidence?: number;
  name: string;
}

export const FormTextField: React.FC<FormFieldProps> = ({ label, confidence, name, ...props }) => {
  return (
    <div>
      <div className="flex items-center">
        <label className="block text-sm font-medium text-gray-700 mb-1">
          {label}
        </label>
        {confidence !== undefined && <ConfidenceIndicator confidence={confidence} fieldName={name} />}
      </div>
      <TextField {...props} />
    </div>
  );
};

export const FormTextArea: React.FC<FormTextAreaProps> = ({ label, confidence, name, ...props }) => {
  return (
    <div>
      <div className="flex items-center">
        <label className="block text-sm font-medium text-gray-700 mb-1">
          {label}
        </label>
        {confidence !== undefined && <ConfidenceIndicator confidence={confidence} fieldName={name} />}
      </div>
      <TextArea {...props} />
    </div>
  );
};
