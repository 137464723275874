import { supabase } from '../config/supabase';
import { User } from '../types';

export const signIn = async (email: string, password: string): Promise<User> => {
  const { data, error } = await supabase.auth.signInWithPassword({
    email,
    password,
  });

  if (error) {
    throw new Error(error.message);
  }

  if (!data.user) {
    throw new Error('No user returned from authentication');
  }

  // Get the user's profile data
  const { data: userData, error: userError } = await supabase
    .from('users')
    .select('*')
    .eq('email', data.user.email)
    .single();

  if (userError) {
    throw new Error(userError.message);
  }

  if (!userData) {
    throw new Error('User profile not found');
  }

  return userData as User;
};

export const signOut = async (): Promise<void> => {
  const { error } = await supabase.auth.signOut();
  if (error) {
    throw new Error(error.message);
  }
};

export const getCurrentUser = async (): Promise<User | null> => {
  const { data: { session }, error } = await supabase.auth.getSession();

  if (error) {
    throw new Error(error.message);
  }

  if (!session?.user) {
    return null;
  }

  const { data: userData, error: userError } = await supabase
    .from('users')
    .select('*')
    .eq('email', session.user.email)
    .single();

  if (userError) {
    throw new Error(userError.message);
  }

  return userData as User;
};
