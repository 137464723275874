import React from 'react';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { SidebarProps, ClientData } from '../../types/dashboard';

const Sidebar: React.FC<SidebarProps> = ({
  clients,
  selectedClient,
  setSelectedClient,
  searchCompany,
  setSearchCompany
}) => {
  const filteredClients = clients.filter(client =>
    client.company_name.toLowerCase().includes(searchCompany.toLowerCase())
  );

  return (
    <div className="w-80 bg-white border-r border-gray-200 flex flex-col">
      <div className="p-4 border-b border-gray-200">
        <div className="relative">
          <input
            type="text"
            placeholder="Ieškoti įmonės..."
            value={searchCompany}
            onChange={(e) => setSearchCompany(e.target.value)}
            className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent"
          />
          <MagnifyingGlassIcon className="h-5 w-5 text-gray-400 absolute left-3 top-1/2 transform -translate-y-1/2" />
        </div>
      </div>

      <div className="flex-1 overflow-y-auto">
        <nav className="flex-1 px-2 py-4 space-y-1">
          {filteredClients.map((client: ClientData) => (
            <button
              key={client.client_id}
              onClick={() => setSelectedClient(client)}
              className={`w-full text-left px-3 py-2 rounded-lg transition-colors duration-150 ${
                selectedClient?.client_id === client.client_id
                  ? 'bg-indigo-50 text-indigo-700'
                  : 'text-gray-700 hover:bg-gray-50'
              }`}
            >
              <div className="font-medium">{client.company_name}</div>
              <div className="text-sm text-gray-500">{client.company_code}</div>
              <div className="text-xs text-gray-400 mt-1">
                {client.access_type === 'owner'
                  ? 'Savininkas'
                  : client.access_type === 'employee'
                  ? 'Darbuotojas'
                  : client.access_type === 'admin'
                  ? 'Administratorius'
                  : 'Įkeliantis dokumentus'}
              </div>
            </button>
          ))}
        </nav>
      </div>
    </div>
  );
};

export default Sidebar;
