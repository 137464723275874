import React from 'react';
import { TableFiltersProps } from '../../types/dashboard';
import Select from '../ui/Select';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';

const TableFilters: React.FC<TableFiltersProps> = ({
  statusFilter,
  setStatusFilter,
  searchQuery,
  setSearchQuery,
  setCurrentPage
}) => {
  const handleStatusChange = (value: string) => {
    setStatusFilter(value);
    setCurrentPage(1);
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1);
  };

  const statusOptions = [
    { value: 'all', label: 'Visi statusai' },
    { value: 'Done', label: 'Apdoroti' },
    { value: 'Error', label: 'Klaida' },
    { value: 'Uploaded', label: 'Įkelti' },
    { value: 'Sent to AI', label: 'Siunčiama' }
  ];

  return (
    <div className="flex space-x-4">
      <div className="relative">
        <input
          type="text"
          placeholder="Ieškoti..."
          value={searchQuery}
          onChange={handleSearchChange}
          className="pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent"
        />
        <MagnifyingGlassIcon 
          className="h-5 w-5 text-gray-400 absolute left-3 top-1/2 transform -translate-y-1/2"
          aria-hidden="true"
        />
      </div>

      <Select
        options={statusOptions}
        value={statusFilter}
        onChange={handleStatusChange}
        className="w-48"
      />
    </div>
  );
};

export default TableFilters;
