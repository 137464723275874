import { supabase } from '../config/supabase';
import { ClientData } from '../types/dashboard';

export const getUserAccessibleClients = async (userId: string): Promise<ClientData[]> => {
  const { data, error } = await supabase
    .rpc('get_user_accessible_clients', {
      p_user_id: userId
    });

  if (error) {
    throw new Error(error.message);
  }

  return data as ClientData[];
};

export const getClientDetails = async (clientId: string) => {
  const { data, error } = await supabase
    .from('clients')
    .select('*')
    .eq('client_id', clientId)
    .single();

  if (error) {
    throw new Error(error.message);
  }

  return data;
};
