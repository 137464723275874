import React from 'react';

interface InvoicePreviewProps {
  fileUrl: string | null;
  mimeType: string;
}

const InvoicePreview: React.FC<InvoicePreviewProps> = ({ fileUrl, mimeType }) => {
  if (!fileUrl) {
    return (
      <div className="bg-gray-50 rounded-lg h-full flex items-center justify-center text-gray-500">
        Nėra failo peržiūrai
      </div>
    );
  }

  const fileType = mimeType?.toLowerCase() || '';
  
  if (fileType.includes('pdf')) {
    return (
      <iframe
        src={fileUrl}
        title="Invoice PDF"
        className="w-full h-full rounded-lg"
        style={{ border: 'none', minHeight: '800px' }}
      />
    );
  } 
  
  if (fileType.includes('image') || fileUrl.match(/\.(jpg|jpeg|png|gif)$/i)) {
    return (
      <div className="bg-gray-50 rounded-lg p-4 flex items-center justify-center h-full">
        <img
          src={fileUrl}
          alt="Invoice"
          className="max-w-full max-h-[800px] object-contain"
          onError={(e) => {
            console.error('Image failed to load:', e);
            const target = e.target as HTMLImageElement;
            target.onerror = null;
            target.src = '';
            target.alt = 'Nepavyko įkelti nuotraukos';
          }}
        />
      </div>
    );
  }

  return (
    <div className="bg-gray-50 rounded-lg h-full flex items-center justify-center text-gray-500">
      Nepalaikomas failo formatas: {fileType}
    </div>
  );
};

export default InvoicePreview;
